import { SignIn } from '@clerk/remix'

import { ClerkLayout } from './sign-up.$'

export default function SignInPage() {
	return (
		<>
			<div className="flex max-w-4xl mx-auto mt-20 align-middle w-max gap-4 flex-row-reverse p-12 bg-[#211a49] rounded-lg">
				<div>
					<SignIn
						appearance={{
							layout: ClerkLayout,
						}}
						fallbackRedirectUrl="/"
					/>
				</div>
				<div className=" w-full text-white justify-center">
					<div className="pt-24">
						<h2 className="text-xl">
							Welcome to a <strong>new login experience.</strong>
						</h2>
						<p className="mt-2">
							We have updated the login process to make it even more secure and
							flexible.
						</p>
						<p className="mt-8 border-t border-violet-300 border-dotted p-6">
							<span className="font-semibold">
								If you created an account before Dec 1
							</span>
							<ol className="list-decimal ml-6 mt-4">
								<li>
									Simply submit your email here and you&rsquo;ll be sent a login
									code to you email.
								</li>
								<li className="mt-4">
									If you use Google or have a linkedin account with the same
									email, you can simply click the Google or Linkedin button to
									sign in.
								</li>
							</ol>
						</p>
					</div>
				</div>
			</div>
		</>
	)
}
